<template>
  <div class="col-span-1 space-y-6 md:sticky md:top-2">
    <div class="space-y-2 text-gray-700">
      <a
        v-for="(category, key) in categories"
        :key="key"
        :id="'category_' + key"
        href="#"
        :class="[
          'w-2/3 block rounded-md bg-muted px-4 py-2 text-lg font-medium text-muted-foreground hover:bg-muted/50 text-left align-middle',
          selectedCategory === key ? 'bg-red-800 text-gray-100 animate__animated animate__flipInX' : ''
        ]"
        @click="selectCategory(key)"
      >
      <i :class="[category.icon, 'align-middle']" />
        {{ category.name }}
      </a>      
    </div>
  </div>
</template>
  
<script>
import 'animate.css';

export default {
  name: "TheSidebar",
  props: {
    selectedCategory: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      categories: {
        news: { name: "最新动态", icon: "ion--earth" }, 
        events: { name: "活动速览", icon: "material-symbols--event" }, 
        message: { name: "到此一游", icon: "material-symbols--chat" },
        // recommendation: { name: "阅读推荐", icon: "ri--book-fill" }, 
        about: { name: "分店信息", icon: "bxs--store" },
      }
    };
  },
  methods: {
    selectCategory(category) {
      this.$emit('update:selectedCategory', category);
    }
  }
};
</script>
  
<style src="../style/icons.css"></style>
